#cookie-eu {
  background: #000;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 999;
  padding: 1em 1.8em;
  box-sizing: border-box;
  box-shadow: 1px 1px 20px rgba(0,0,0,.4)
}

.cookie-eu {
  main,
  aside {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    align-items: center;
  }

  main {
    justify-content: flex-start;
    background-image: none;
  }

  aside {
    justify-content: flex-end;
    text-align: center;
  }

  h3,
  a {
    display: inline; 
    padding: 0 0 0 8px;
  }
}

@media screen and (max-width: 500px) {
  .cookie-eu {display: none;}
  .cookie-eu main,.cookie-eu aside {display: block;width: 100%;text-align: center;}
  .cookie-eu aside {padding: 20px 0 0 0;}
}
.main-header {
  background: $black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  height: $nav;

  @media only screen and (min-width: $windowSize) {
    height: $nav-desktop;
  }

  nav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 7%;
  }

  .contacts,
  .logo {
    margin-right: 0;
  }

  a {
    font-family: $titleFont;
    color: $white;
    text-transform: uppercase;
    font-size: $font-small;

    &:hover,
    &:active {
      color: $yellow;
    }
  }

  .logo {
    justify-self: flex-start;
    margin-right: auto;
    display: block;
    max-width: 150px;

    @media screen and (min-width: $windowSize) {
      max-width: 250px;
    }
  }

  .opposition-logo {
    display: none;

    @media screen and (min-width: $windowSize) {
      display: inline-block;
    }
  }

  .opposition-logo2 {
    display: inline-block;

    @media screen and (min-width: $windowSize) {
      display: none;
    }
  }
}

.nav-links {
  position: fixed;
  top: $nav;
  height: 0;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  display: flex;
  background: $black;
  transition: height 0.3s ease-out;
  overflow: hidden;

  .opened & {
    height: calc(100vh - #{$nav});
  }

  @media only screen and (min-width: $windowSize) {
    max-width: 1273px;
    justify-content: flex-end;
    flex-direction: row;
    position: static;
    height: auto;
  }

  li {
    text-align: right;
    padding: 20px 7%;
  }
}

.nav-toggle {
  display: inline-block;
  width: 34px;
  height: 30px;
  position: relative;
  outline: 0;
  border: 0;
  padding: 0;
  flex-shrink: 0;
  margin-left: $spacing-unit;

  @media only screen and (min-width: $windowSize) {
    display: none;
  }

  &::before,
  &::after {
    content: "";
    transform-origin: 0 0;
    transition: transform 0.3s ease-out;
  }

  &::before {
    top: 0;

    .opened & {
      transform: rotate(45deg) translate(7px, -4px)
    }
  }

  &::after {
    bottom: 0;

    .opened & {
      transform: rotate(-45deg) translate(3px, 3px);
    }
  }

  span {
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.3s ease-out;
    opacity: 1;

    .opened & {
      opacity: 0;
    }
  }

  span,
  &::before,
  &::after {
    position: absolute;
    display: block;
    height: 4px;
    width: 100%;
    background-color: $white;
  }
}

@media only screen and (min-width: $windowSize) {
  .main-header {
    display: inline-block;

    li {
      display: inline-block;
      padding: 0;
      padding-right: 65px;

      &:last-child {
        padding-right: 0;
      }
    }
  }
}
.header-title {
	padding: 49px 46px 47px; 
	margin-bottom: 38px;

	.flex {
		align-items: center;
	}

	.opposition-logo {
		width: 55px;
		margin-right: $spacing-unit/2;
	}

	h2 {
		max-width: 468px;
	}

	.category,
	.created-at {
		color: $yellow;
		font-size: $font-smaller;
	}

	.category {
		margin-left: 5px;
		display: inline-block;
	}

	.created-at {
		border-left: 1px solid $yellow;
		padding-left: 5px;
  }

	.post-meta {
		padding-left: calc(55px + #{$spacing-unit/2});
	}

	@media screen and (max-width: $tabletSize - 1) {
		.post-meta {
			padding-left: 0;
		}

		.opposition-logo {
			display: none;
		}
	}
}
.post {
	.container {
		max-width: 920px;
	}
	
	.post-content {
		h1, h2, h3, p {
			margin-bottom: 20px;
		}

		figure {
			width: 100%;
			text-align: center;
		}
		img {
			max-width: 100%;
		}
		blockquote {
			background-color: $yellow;
			width: 96%;
			padding: 2%;
			margin: 0 0 10px 0;
			ul {
				li {
					list-style: none;
				}
      }
      
      p {
        margin-bottom: 10px;
				font-weight: $font-black;
      }
		}
		ul, ol {
			margin-bottom: 20px;
			margin-left: $spacing-unit/2;
			p {
				margin-bottom: 0;
			}
		}
		ul {
			li {
				list-style: circle;
			}
		}
		ol {
			li {
				list-style: decimal;
			}
		}
		.video-wrapper {
			position: relative;
			padding-bottom: 56.25%; /* 16:9 */
			padding-top: 25px;
			height: 0;
			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
		.main-cover {
			margin: 0 0 20px 0;
		}
		footer {
			margin: 30px 0;
		}
	}
	
  iframe {
    max-width: 100%;
  }
}

@charset "utf-8";

// Define defaults for each variable.
$titleFont: "termina", sans-serif;
$base-font-family: 'Poppins', sans-serif !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

// New colours
$black: #000000;
$white: #ffffff;
$yellow: #f2cb01;
$coral: #f25041;
$pink: #f2c4d0;
$purple: #6c0df2;
$purple-dark: #3f0a8c;
$green: #05a66a;
$dark-grey: #191919;

// Font
$font-h1: 60px;
$font-h2: 40px;
$font-h3: 24px;
$font-body: 20px;
$font-body-md: 16px;
$font-small: 14px;
$font-smaller: 12px;
$font-tiny: 10px;

$font-black: 900;
$font-bold: 700;
$font-semibold: 600;
$font-light: 300;

$accentFontColor: #c1a468;
// $mainFontColor: #2e2e2e;
$backgroundColor: #161616;
$navColor: #FFFCFC;

// Width of the content area
$xlargeWindowSize: 2000px;
$windowSize: 1025px;
$tabletSize: 720px;
$maxWidth: 720px;
$largeMobileSize: 500px;
$footerDesktop: 1034px;

// Nav height
$nav: 78px;
$nav-desktop: 120px;

// Utility classes
.text-white {
  color: $white;
}

.text-black {
  color: $black;
}

.fontsize-body {
  font-size: $font-body;
}

.font-body {
  font-family: $base-font-family;
  font-weight: $font-light;
}

.text-center {
  text-align: center;
}

.font-black {
  font-weight: $font-black;
}

.font-semibold {
  font-weight: $font-semibold;
}

.uppercase {
  text-transform: uppercase;
}

.bg-purple {
  background-color: $purple
}

.bg-none {
  background: transparent;
}

.bg-black {
  background-color: $black;
}

.bg-darkgrey {
  background-color: $dark-grey;
}

.hover-bg-none {
  &:hover {
    background: transparent;
  }
}

.container {
  max-width: 1390px;
  margin-left: auto;
  margin-right: auto;
  padding-left: $spacing-unit/2;
  padding-right: $spacing-unit/2;
  box-sizing: content-box;

  @media screen and (max-width: $windowSize - 1) {
    padding-left: $spacing-unit;
    padding-right: $spacing-unit;
  }
}

.flex {
  display: flex;
}

.col-6 {
  width: 50%;
  position: relative;
}

.hidden {
  overflow: hidden;
}

.hide-lg {
  @media screen and (min-width: $windowSize) {
    display: none;
  }
}

@mixin center {
  float: none;
  max-width: $maxWidth;
  width: 90%;
  padding: 0 5%;
  margin: 0 auto;
  @media only screen and (min-width: $windowSize) {
    width: 100%;
    padding: 0;
  }
}

@mixin submit-button {
  background: $accentFontColor;
  color: #2e2e2e;
  text-transform: uppercase;
  font-size: 1em;
  padding: 0 15px;
  border: none;
  cursor: pointer;
  &.disabled {
    background: #ccc;
    pointer-events: none;
    cursor: no-drop;
  }
}

@mixin proportionalArtistContainer($parent, $containerPercentageWidth, $padding) {
  #{$parent} {
    width: #{$containerPercentageWidth};

    .artist-content {
      padding-top: $padding;
    }
  }
}

.page-header {
  margin-top: $nav;
  width: 100%;
  overflow: hidden;
  margin-bottom: 74px;

  @media only screen and (min-width: $windowSize) {
    margin-top: $nav-desktop;
  }

  img {
    width: 100%;
    max-width: 1920px;
    display: block;
  }
}

.social-media {
  a {
    &:hover {
      path {
        fill: $yellow;
      }
    }
  }
}

// Import partials.
@import "base/reset";
@import "base/header";
@import "base/footer";
@import "base/cookieBanner";
@import "home/about";
@import "home/home";
@import "home/partners";
@import "home/services";
@import "home/platform";
@import "page/news";
@import "post/post";

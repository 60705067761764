.main-footer {
  background: $coral;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 7%;
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;

  @media screen and (min-width: $footerDesktop) {
    flex-direction: row; 
  }

  .opposition-logo {
    display: none;

    @media screen and (min-width: $footerDesktop) {
      display: inline-block;
    }
  }

  .opposition-logo2 {
    display: inline-block;
    max-width: 150px;

    @media screen and (min-width: $footerDesktop) {
      display: none;
    }

    @media screen and (max-width: $largeMobileSize - 1) {
      max-width: 120px
      
    }
  }

  .icon, 
  ul {
    display: inline-block;
  }

  .icon {
    font-size: 4em;
  }
  .opposition-logo,
  .bbtv-logo {
    height: 33px;
  }

  .social-media {
    padding: 0;
    margin-left: auto;
    display: flex;
    align-items: center;

    @media screen and (max-width: $footerDesktop - 1) {
      flex-direction: row-reverse;
    }
    
    li {
      display: inline-block;
      margin-right: 24px;

      @media screen and (max-width: $footerDesktop - 1) {
        &:first-child {
          margin-right: 0;
        }
      }

      @media screen and (max-width: $largeMobileSize - 1) {
        margin-right: 15px;
      }
    }

    img {
      height: 31px;
      display: inline-block;
    }
  }

  .icon-YouTube-certified {
    order: 3;

    @media screen and (min-width: $footerDesktop) {
      order: 1;
      padding-right: 4%;
    }
  }
}

.footer-links {
  padding-left: 0;
  padding-right: 10px;
  order: 4;
  text-align: center;

  @media screen and (min-width: $footerDesktop) {
    order: 2;
  }

  li {
    display: inline;
    font-size: $font-tiny;
    font-weight: $font-semibold;
    
    a {
      margin: 0 5px 0 0;
      padding: 0 11px 0 0;
    }

    a {
      border-right: 1px solid $white;
    }

    &:nth-last-child(2) {
      a {
        border-right: 0;

        @media screen and (min-width: $footerDesktop) {
          border-right: 1px solid $white;
        }
      }
    }

    &:last-child {
      display: block;
      padding-top: 10px;

      @media screen and (min-width: $footerDesktop) {
        padding: 0;
        display: inline;
      }
    }
  }
}

.footer-right {
  display: flex;
  align-items: center;
  justify-self: flex-end;
  order: 2;
  width: 100%;
  flex-direction: row-reverse;
  padding-top: 20px;
  padding-bottom: 35px;

  @media screen and (min-width: $footerDesktop) {
    order: 3;
    padding: 0;
    margin-left: auto;
    width: auto;
    flex-direction: row;
  }
}


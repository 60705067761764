* {
  margin: 0;
  padding: 0;
  color: $black;
  font-family: $base-font-family;
  font-weight: $font-light;
}
h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
  font-family: $titleFont;
  font-weight: $font-black;
}
h1,
.h1 {
  font-size: $font-h1;

  @media screen and (max-width: $tabletSize - 1) {
    font-size: $font-h3;
  }

  @media screen and (max-width: $largeMobileSize - 1) {
    font-size: $font-body;
  }
}

h2,
.h2 {
  font-size: $font-h2;

  @media screen and (max-width: $tabletSize - 1) {
    font-size: $font-h3;
  }

  @media screen and (max-width: $largeMobileSize - 1) {
    font-size: $font-body;
  }
}

h3,
.h3 {
  font-size: $font-h3;

  @media screen and (max-width: $tabletSize - 1) {
    font-size: $font-body;
  }

  @media screen and (max-width: $largeMobileSize - 1) {
    font-size: $font-body-md
  }
}

a {
  text-decoration: none;
  color: $black;
  transition: .3s all;
  font-weight: $font-black;

  &:hover {
    color: $yellow;
    transition: .3s all;
  }
}

strong {
  font-weight: $font-bold;
}

li {
  list-style: none;
}
img {
  max-width: 100%;
}

button {
  border: 0;
}

button,
.button {
  background-color: $purple;
  color: $white;
  font-weight: $font-black;
  border-radius: 50%;
  font-size: $font-smaller;
  border-radius: 25px;
  line-height: 37px;
  padding: 0 36px;
  transition: .3s all;

  &:hover {
    background-color: $purple-dark;
    cursor: pointer;
  }
}

.grecaptcha-badge {
  display: none;
}

body {
  &.prevent-scroll {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
}

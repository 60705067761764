.services {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $windowSize - 1) {
    padding-top: 75px;
    background-image: url("../../assets/img/2021/services2x.png");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba($black, 0.7);
      position: absolute;
      top: 0;
      left: 0;
    }
  }


  h2 {
    @media screen and (min-width: $windowSize) {
      padding-right: 100px;
      font-size: $font-h1;
    }
  }

  @media only screen and (min-width: $windowSize) {
    flex-direction: row;
  }

  .container {
    padding-top: 50px;
    padding-bottom: 100px;

    @media only screen and (min-width: $windowSize) {
      padding-top: 100px;
    }
  }

  ul {
    text-align: center;

    @media only screen and (min-width: $windowSize) {
      text-align: left;
      padding-left: 45px;
    }
  }

  li {
    padding-bottom: 10px;
    padding-top: 10px;
    color: $white;
    font-family: $titleFont;
    font-weight: $font-black;
    font-size: $font-body-md;
    opacity: 0.2;
    transition: .3s opacity;

    @media only screen and (min-width: $tabletSize) {
      font-size: $font-body;
    }

    @media only screen and (min-width: $windowSize) {
      padding-bottom: 18px;
      padding-top: 18px;
      font-size: 25px;
    }

    &:hover {
      cursor: default;
      color: $yellow;
      opacity: 1;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .col-6 {
    &:first-child {
      background: url("../../assets/img/2021/services2x.png") top left no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    @media only screen and (max-width: $windowSize - 1) {
      &:first-child {
        background: none;
        justify-content: center;
      }

      width: 100%;
    }
  }
}

#platform {
  background: $accentFontColor;
  padding: 30px 0;
  ul {
    @include center;
    width: 100%;
    padding: 0%;
    display: block;
  }
  li {
    flex: 1 auto;
    width: 90%;
    padding: 0 5%;
    &:nth-child(2) {
      img {
        max-width: 70px;
      }
    }
  }
  h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    float: none;
    background: #2e2e2e;
    width: 130px;
    height: 130px;
    margin: 0 auto;
    border-radius: 50%;
    img {
      flex: 1 auto;
      max-width: 100px;
      // max-height: 80px;
      // max-width: 90px;
    }
  }
  p {
    font-size: .75em;
    line-height: 2em;
    text-align: left;
    margin: 20px 0 0 0;

  }
}

@media only screen and (min-width: $windowSize) {
  #platform {
    padding: 0;
    ul {
      display: flex;
      border-right: 1px solid black;
    }
    li {
      padding: 5%;
      border-left: 1px solid black;
    }
    p {
      text-align: justify;
    }
  }
}

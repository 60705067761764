.hero {
  height: calc(100vh - #{$nav});
  position: relative;
  background-color: $black;
  margin-top: $nav;

  @media only screen and (min-width: $windowSize) {
    height: calc(100vh - #{$nav-desktop});
    margin-top: $nav-desktop;
  }
}

.bg-video,
.hero-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg-video {
  overflow: hidden;

  iframe {
    width: 100vw;
    height: 56.25vw; // Given a 16:9 aspect ratio, 9/16*100 = 56.25
    min-height: 100vh;
    min-width: 177.77vh; // Given a 16:9 aspect ratio, 16/9*100 = 177.77 
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.hero-content {
  background-color: rgba($black, 0.5);

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    position: relative;
  }

  .social-media {
    position: absolute;
    left: $spacing-unit;
    bottom: 0;

    @media screen and (min-width: $windowSize) {
      left: $spacing-unit/2;
    }

    li {
      padding-bottom: 20px;

      @media screen and (min-width: $windowSize) {
        padding-bottom: 50px;  
      }
    }
  }

  h1 {
    transform: translateY(-20%);

    @media screen and (min-width: $windowSize) {
      transform: none;
    }
  }
}
.page {
  max-width: 1030px;
  .post-list {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    text-align: left;
    li {
      padding-bottom: 74px;
    }
    figure, img {
      width: 100%;
    }

    figure,
    .post-description,
    .pagination {
      width: 100%;
      margin-left: auto;
    
      @media only screen and (min-width: $windowSize) {
        width: 88.8%;
      }
    }

    .post-description {
      border-bottom: 2px solid black;
      padding-bottom: 37px;
      font-size: $font-body-md;
      
      @media only screen and (min-width: $windowSize) {
        font-size: $font-body;
      }
    }

    figure {
      position: relative;
      background-size: cover !important;
      overflow: hidden;
      margin-bottom: 20px;

      &::before {
        display: block;
        content: "";
        padding-top: 50.16%;
      }
    }
    .post-details {
      width: 77px;
    }
    .post-details, h2 {
      display: inline-block;
      vertical-align: middle;
    }
    h2 {
      text-transform: none;
      letter-spacing: 0;
      line-height: 1.1em;
      overflow: hidden;
      margin-left: 2%;
      max-width: calc(90% - 77px);
      text-align: center;
      @media only screen and (min-width: $windowSize) {
        text-align: left;
        overflow: inherit;
        margin-left: 5%;
        max-width: 68%;
      }
    }
    header {
      position: relative;
      background: $black;
      display: inline-block;
      width: 95%;
      padding: 2.5%;
      z-index: 2;

      @media only screen and (min-width: $windowSize) {
        padding: 29px 40px 35px 40px;
        width: calc(100% - 69px);
        max-width: 702px;
      }
      
      .month,
      .day {
        font-family: $titleFont;
        font-weight: $font-black;
      }

      .month {
        font-size: $font-smaller;
      }
      
      .day {
        font-size: 32px;
      }
      .social-media {
        display: flex;
        clear: both;
      }
      .social-media-button {
        text-align: center;
        flex: 1 auto;
        padding: 9px 0 0 0;
        width: 50%;
      }
      .twitter-button {
        border-right: 1px solid $white;
      }
    }
    time {
      float: left;
      text-align: center;
      padding: 10px;
      border-bottom: 1px solid $white;
      width: calc(100% - 20px);
    }
  }
  .pagination {
    padding-bottom: 73px;
    margin-top: -40px;

    a {
      font-size: 16px;
    }

    .previous, .next {
      display: inline-block;
      text-transform: uppercase;
    }
    .previous {
      float: left;
       + .pagination-bar {
        li {
          &:first-child {
            padding-left: 15px;
          }
        }
       }
    }
    .next {
      float: right;
    }
    ul {
      display: inline-block;
    }
    li {
      display: inline-block;
      padding: 0 15px;

      &:first-child {
        padding-left: 0;
      }
    }
    .selected {
      a {
        color: $yellow;
      }
    }
  }
}

.post-details {
  .twitter {
		width: 20px;
	}

	.social-media {
		svg {
			height: auto;
		}
		a {
			&:hover {
				path {
					fill: $pink;
				}
			}
		}
	}
}

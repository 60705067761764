.talent {
  background-color: $black;
  padding-top: 74px;
  padding-bottom: 100px;

  @media screen and (min-width: $windowSize){
    padding-bottom: 142px; 
  }

  .flex {
    @media screen and (max-width: $tabletSize - 1) {
      flex-direction: column;
      align-items: center;
    }
  }

  h2 {
    padding-bottom: 66px;
  }
}

.artist-content {
  width: 100%;
  padding-top: 50%; /* defines aspect ratio */
  position: relative;
}

.artist-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -2px;
    width: calc(100% + 7px);
    height: 100%;
    background-color: rgba($black, 0.6);
    z-index: 1;
    opacity: 1;
    transition: .3s all;
  }
}

.artist-container {
  display: flex;
}

.artist {
  display: block;
  width: 50%;

  @media screen and (max-width: $tabletSize - 1) {
    margin-bottom: $spacing-unit;
  }
  
  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  span {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: .3s all;
    padding: 21px 0;
    z-index: 1;

    @media screen and (max-width: $windowSize - 1) {
      font-size: $font-small;
    }
  }

  &:hover {
    span {
      background-color: $purple;
    }

    .artist-inner {
      &::before {
        opacity: 0;
      }
    }
  }
}

@include proportionalArtistContainer('.artist0', 100%, percentage(360/1390));
@include proportionalArtistContainer('.artist1', 50%, percentage(360/695));
@include proportionalArtistContainer('.artist2', 57.84%, percentage(360/804));
@include proportionalArtistContainer('.artist3', 42.16%, percentage(360/586));

@include proportionalArtistContainer('.artist4', 33.67%, percentage(720/468));
.brick-left {
  width: 66.33%;
}
@include proportionalArtistContainer('.artist5', 61.82%, percentage(360/570));
@include proportionalArtistContainer('.artist6', 38.18%, percentage(360/352));
@include proportionalArtistContainer('.artist7', 61.82%, percentage(360/570));
@include proportionalArtistContainer('.artist8', 38.18%, percentage(360/352));

@include proportionalArtistContainer('.artist9', 41.01%, percentage(720/570));
.brick-right {
  width: 58.99%;
}

@include proportionalArtistContainer('.artist10', 100%, percentage(360/820));
@include proportionalArtistContainer('.artist11', 44.63%, percentage(360/366));
@include proportionalArtistContainer('.artist12', 55.37%, percentage(360/454));

@media screen and (max-width: $tabletSize - 1) {
  .artist,
  .brick-left,
  .brick-right {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  .brick-left,
  .brick-right {
    .artist {
      width: 100%;
    } 
  }

  .artist0 {
    margin-bottom: 0;
  }

  .artist {
    .artist-content { 
      padding-top: 100%;
    }
  }
}
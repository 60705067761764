.intro {
  padding-top: 80px;
  padding-bottom: 96px;

  .opposition-logo {
    margin-bottom: 45px;
  }

  p {
    max-width: 970px;
    margin: 0 auto;
    font-size: $font-h3;

    @media screen and (max-width: $tabletSize - 1) {
      font-size: $font-body-md;
    }
  }
}